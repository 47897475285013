<template>
  <div class="content">
    <div class="nav">
      <HeaderBase :sear="'hide'"/>
    </div>
    <div class="body">
      <el-result
        icon="success"
        :title="$t('m.buy_settleaccounts.You_have_paid_successfully')"
        :sub-title="`${$t('m.paySuccess.Actual_payment')}：`+payMoney"
      >
        <template #extra>
          <el-button type="primary" @click="todetail">{{$t('m.paySuccess.view_orde_details')}}</el-button>
        </template>
      </el-result>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue-demi'
import { getStore } from '../../tools/storage'
import HeaderBase from '../../components/HeaderBase.vue'
const headernav = reactive([
  { text: '首页', path: '/' },
  { text: '登录', path: '/loginandregister' },
  { text: '移动端', path: '' }
])

export default {
  components: { HeaderBase },
  name: 'PaySuccessMain',
  data () {
    return {
      payMoney: ref('')
    }
  },
  methods: {
    todetail () {
      this.$nextTick(() => {
        this.$store.state.personalNumber = 4
        this.$router.push('/personalcenter/myorder')
      })
      // window.location.reload()
    }
  },
  mounted () {
    this.payMoney = getStore('payMoneyNumber')
    // removeStore('payMoneyNumber')
  },
  setup () {
    return {
      headernav
    }
  }
}
</script>

<style  lang="scss" >
@import '../../style/viriables.scss';

.content{
  width: 100%;
  background: $divbgColor;
  height:98vh;
  .nav{
    // height: 30px;
    width: 100%;
    background: $divbgColor;
    ul{
      display: block;
      line-height: 30px;
      width: 1200px;
      margin: 0 auto;
      height: 30px;
      li{
        float: left;
        display: flex;
        height: 30px;
        margin-right: 20px;
        cursor: pointer;
      }
    }
    #search{
      width: 100%;
      height: 120px;
      background: #ffffff;
      .logosearch{
        display: flex;
        width: 1200px;
        margin: 0 auto;
        height: 100%;
        z-index: 99;
        justify-content: left;
        align-items: center;
        #logo{
          img{
            cursor: pointer;
            margin-right: 80px;
            z-index: 9999;
          }
        }
      }
    }
  }
  .body{
    margin-top: 60px;
    width: 1200px;
    margin: 0 auto;
    font-family: Microsoft YaHei;
    font-weight: 400;
    .el-result{
      padding: 60px 30px;
      .el-result__title{
        p{
          font-size: 18px;
          color: #53A724;
        }
      }
      .el-result__subtitle{
        margin: 60px 0;
        p{
          font-size: 36px;
          color: #333333;
        }
      }
      .el-result__extra{
        margin: 0;
        .el-button{
          width: 160px;
          height: 50px;
          background: linear-gradient(0deg, #280E01 0%, #673000 100%);
          border-color: transparent;
        }
      }
    }
  }
}
</style>
